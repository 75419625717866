import { useTransition, animated } from "react-spring";
import React, { useState } from "react";
import banner from "../../assets/images/hero.png";
import Menu from "./Menu";
import logo from "../../assets/images/logo.png";
import big1 from "../../assets/images/big-1.jpeg";
import big2 from "../../assets/images/big-2.png";
import small1 from "../../assets/images/small-1.png";
import small2 from "../../assets/images/small-2.png";
import small3 from "../../assets/images/small-3.png";
import small4 from "../../assets/images/small-4.png";
import small5 from "../../assets/images/small-5.png";
import small6 from "../../assets/images/small-6.png";
import small7 from "../../assets/images/small-7.png";
import small8 from "../../assets/images/small-8.png";

const Home = () => {
  document.title = "Shree Ram Bakers";

  const initialItems = Menu;

  // 7887284485

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    dob: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct WhatsApp link with form data
    const whatsappLink = `https://wa.me/7887284485?text=Name: ${formData.name}%0APhone: ${formData.phone}%0ADob: ${formData.dob}%0AMessage: ${formData.message}`;

    window.open(whatsappLink, "_blank");
  };

  const [items, setItems] = useState(initialItems);
  const [selectedCategory, setSelectedCategory] = useState("All");

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    if (category === "All") {
      setItems(initialItems);
    } else {
      const filteredItems = initialItems.filter(
        (item) => item.category === category
      );
      setItems(filteredItems);
    }
  };

  const transitions = useTransition(items, {
    from: { opacity: 0, transform: "translateY(50px)" },
    enter: { opacity: 1, transform: "translateY(0)" },
    leave: { opacity: 0, transform: "translateY(-50px)" },
  });

  return (
    <>
      <div className="hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-10 align-self-center col-xs-5 hero-bg">
              <h1>
                Indulge in <span>Sweet</span> Moments, <span>Every Day!</span>
              </h1>
              <p>
                Savor the artistry of our freshly baked delights at Shri Ram
                Bakers. From decadent pastries to artisanal bread, our passion
                for perfection transforms every bite into a delightful
                experience.
              </p>
              <div className="d-flex gap-2 py-3">
                <a href="https://wa.me/917887284485?text=Hii" target="_blank">
                  <button className="primary-button">Order now</button>
                </a>
                <a href="#menu">
                  <button className="border-button">View Menu</button>
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-11 mx-auto bg-blue col-xs-5">
              <img src={banner} className="w-100" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="about py-5 my-5" id="about">
        <div className="container text-center">
          <h1>
            <span>About</span> Us
          </h1>
          <p>
            Welcome to Shree Ram Bakers, your go-to destination for a delightful
            blend of traditional and innovative baked goods in the heart of
            Gorakhpur. Our bakery and sweets shop welcomes you with the
            irresistible scent of freshly baked treats, promising a symphony of
            flavors and sweet moments to cherish. At Shree Ram Bakers, we take
            pride in our thoughtfully curated menu that seamlessly marries
            classic favorites and cutting-edge creations. Each delectable
            offering, from luscious cakes to flaky pastries, buttery cookies,
            and a diverse range of assorted sweets, reflects our unwavering
            dedication to top-notch quality and exceptional taste. Step into our
            inviting bakery, where the cozy ambiance and the warm smiles of our
            staff ensure that every visit is a memorable experience. Whether
            you're commemorating a special occasion, treating yourself to a
            sweet delight, or simply exploring the world of artisanal baking,
            we're here to make each moment extra special.
          </p>
        </div>
      </div>
      <div className="our-menu my-5 py-5" id="menu">
        <div className="container">
          <div className="d-flex justify-content-between items-center">
            <div className="col-lg-6 col-6">
              {" "}
              <h1>
                Our <span>Menu</span>
              </h1>
            </div>
            <div className="col-lg-3 align-self-center col-3">
              <form action="">
                <select
                  name=""
                  value={selectedCategory}
                  onChange={(e) => handleCategoryChange(e.target.value)}
                  className="form-select"
                  id=""
                >
                  <option value="All" selected>
                    All
                  </option>
                  <option value="shake">Shakes</option>
                  <option value="hot drink">Hot Drinks</option>
                  <option value="burger">Burger</option>
                  <option value="sandwich">Sandwich</option>
                  <option value="rice">Fried Rice</option>
                  <option value="cake">Cake</option>
                  <option value="cookies">Cookies</option>
                  <option value="indo chinese">Indo Chinese</option>
                </select>
              </form>
            </div>
          </div>
          <div className="row gap-3 justify-content-center py-5">
            {/* Wrap menu items with transitions */}
            {transitions((style, menuItem) => (
              <animated.div
                style={style}
                className="col-lg-2 col-5 box shadow-sm rounded gallery-item"
                key={menuItem.id}
              >
                <div className="overlay"></div>
                <img src={menuItem.img} className="w-100" alt={menuItem.name} />
                <hr />
                <h6 className="text-center">{menuItem.name}</h6>
              </animated.div>
            ))}
          </div>
        </div>
      </div>

      <div className="gallery py-5 my-5" id="gallery">
        <div className="container">
          <h1 className="text-center">
            Our <span>Gallery</span>
          </h1>

          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6 p-1">
                  <img
                    src={small1}
                    class="rounded w-100 p-1 bg-white shadow-sm"
                    alt=""
                  />
                </div>
                <div class="col-md-6 p-1">
                  <img
                    src={small2}
                    class="rounded w-100 p-1 bg-white shadow-sm"
                    alt=""
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 p-1">
                  <img
                    src={small3}
                    class="rounded w-100 p-1 bg-white shadow-sm"
                    alt=""
                  />
                </div>
                <div class="col-md-6 p-1">
                  <img
                    src={small4}
                    class="rounded w-100 p-1 bg-white shadow-sm"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6 align-self-center">
              <img
                src={big1}
                class="rounded w-100 p-1 shadow-sm bg-white"
                alt=""
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 align-self-center">
              <img
                src={big2}
                class="rounded w-100 p-1 shadow-sm bg-white"
                alt=""
              />
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6 p-1">
                  <img
                    src={small5}
                    class="rounded w-100 p-1 bg-white shadow"
                    alt=""
                  />
                </div>
                <div class="col-md-6 p-1">
                  <img
                    src={small6}
                    class="rounded w-100 p-1 bg-white shadow"
                    alt=""
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 p-1">
                  <img
                    src={small7}
                    class="rounded w-100 p-1 bg-white shadow"
                    alt=""
                  />
                </div>
                <div class="col-md-6 p-1">
                  <img
                    src={small8}
                    class="rounded w-100 p-1 bg-white shadow"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact py-5" id="contact">
        <div className="container">
          <div className="row gap-3">
            <div className="col-lg-6">
              <h1>
                Contact <span>Us</span>
              </h1>
              <form action="" onSubmit={handleSubmit}>
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    id="floatingInput"
                    required
                    placeholder="Enter Name"
                  />
                  <label for="floatingInput">
                    Name <span>*</span>
                  </label>
                </div>
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="floatingInput"
                    required
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="name@example.com"
                  />
                  <label for="floatingInput">
                    Phone <span>*</span>
                  </label>
                </div>
                <div class="form-floating mb-3">
                  <input
                    type="date"
                    class="form-control"
                    id="floatingInput"
                    name="dob"
                    value={formData.dob}
                    onChange={handleChange}
                    placeholder="name@example.com"
                  />
                  <label for="floatingInput">Dob</label>
                </div>
                <div class="form-floating mb-3">
                  <textarea
                    class="form-control"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    required
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    style={{ height: "150px" }}
                  ></textarea>
                  <label for="floatingTextarea2">
                    Message <span>*</span>
                  </label>
                </div>
                <input
                  type="submit"
                  className="form-control primary-button py-3 shadow-sm"
                  name=""
                  id=""
                />
              </form>
            </div>
            <div className="col-lg-5">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14254.488030245471!2d83.3885037!3d26.7245201!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3991437237e5a62d%3A0xfebe4e725e8dd32e!2sShree%20Ram%20Bakers%20Sweets%20%26%20Cafe!5e0!3m2!1sen!2sin!4v1702721126658!5m2!1sen!2sin"
                width="100%"
                height="500"
                className="rounded"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="footer py-5 mt-5"> 
        <div className="container text-center">
       <hr />
          <div className="row gap-5 py-3">
            <div className="col-lg-3 col-sm-3 mx-auto col-10 ">
              <img src={logo} className="w-75 mx-auto" alt="" />
            </div>
            <div className="col-lg-3">
              <h3>
                Useful <span>Links</span>
              </h3>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#menu">Menu</a>
              </li>
              <li>
                <a href="#gallery">Gallery</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>
            </div>
            <div className="col-lg-5">
              <p>
                Savor the artistry of our freshly baked delights at Shri Ram
                Bakers. From decadent pastries to artisanal bread, our passion
                for perfection transforms every bite into a delightful
                experience.
              </p>
              <div className="d-flex justify-content-center gap-3">
                <a href="https://www.instagram.com/shreerambakersgkp/" target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-brand-instagram"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" />
                    <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                    <path d="M16.5 7.5l0 .01" />
                  </svg>
                </a>
                <a href="https://www.facebook.com/shreerambakersGKP" target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-brand-facebook"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                  </svg>
                </a>
                <a href="https://wa.me/917887284485?text=Hii"
                target="_blank">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-brand-whatsapp"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
                    <path d="M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1" />
                  </svg>
                </a>
              </div>
              <div className="py-3 d-flex justify-content-center">
                <a href=""><button className="primary-button">Feedback</button></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright text-center">
        <p className="p-2 mb-0 text-light">
          <span>&copy; </span>Copyright <span>2023</span> All Right Reserved |
          Design By{" "}
          <a href="https://juneco.in" target="_blank">
            Juneco Infotech Pvt Ltd
          </a>
        </p>
      </div>
    </>
  );
};

export default Home;
