import React, { useState } from "react";
import logo from "../../assets/images/logo.png";

const Navbar = () => {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [activeNavItem, setActiveNavItem] = useState("Home");

  const handleNavToggle = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  const handleNavItemClick = (itemName) => {
    setActiveNavItem(itemName);
    setIsNavCollapsed(true);
  };

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <a className="navbar-brand" href="#">
          <img src={logo} width={80} alt="Shri Ram Bakers Gorakhpur Logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded={!isNavCollapsed ? true : false}
          aria-label="Toggle navigation"
          onClick={handleNavToggle}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-menu"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M4 8l16 0" />
            <path d="M4 16l16 0" />
          </svg>
        </button>
        <div
          className={`collapse navbar-collapse justify-content-end ${
            isNavCollapsed ? "" : "show"
          }`}
          id="navbarNav"
        >
          <ul className="navbar-nav ">
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeNavItem === "Home" ? "active" : ""
                }`}
                aria-current="page"
                href="#"
                onClick={() => handleNavItemClick("Home")}
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeNavItem === "About Us" ? "active" : ""
                }`}
                href="#about"
                onClick={() => handleNavItemClick("About Us")}
              >
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeNavItem === "Our Menu" ? "active" : ""
                }`}
                href="#menu"
                onClick={() => handleNavItemClick("Our Menu")}
              >
                Our Menu
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeNavItem === "Gallery" ? "active" : ""
                }`}
                href="#gallery"
                onClick={() => handleNavItemClick("Gallery")}
              >
                Gallery
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link ${
                  activeNavItem === "Contact Us" ? "active" : ""
                }`}
                href="#contact"
                onClick={() => handleNavItemClick("Contact Us")}
              >
                Contact Us
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://wa.me/917887284485?text=Hii"
                target="_blank"
              >
                <button className="primary-button">Order now</button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
