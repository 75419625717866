import mangoShake from "../../assets/images/mango-shake.png"
import orangeShake from "../../assets/images/orange-shake.png"
import pineappleShake from "../../assets/images/pineapple-shake.png"
import strawberryShake from "../../assets/images/strawberry-shake.png"
import oreoShake from "../../assets/images/oreo-shake.png"
import vanillaShake from "../../assets/images/vanilla-shake.png"
import butterShake from "../../assets/images/butter-shake.png"
import mishrambu from "../../assets/images/mishrambu-shake.png"
import choclateShake from "../../assets/images/choclate-shake.png"
import coldCoffee from "../../assets/images/cold-coffee.png"
import hotCoffee from "../../assets/images/hot-coffee.png"
import hotTea from "../../assets/images/hot-tea.png"
import vegBurger from "../../assets/images/veg-burger.png"
import cheeseBurger from "../../assets/images/cheese-burger.png"
import paneerBurger from "../../assets/images/paneer-burger.png"
import vegSandwich from "../../assets/images/veg-sandwich.png"
import grilledSandwich from "../../assets/images/grilled-sandwich.png"
import cheeseSandwich from "../../assets/images/chinese-sandwich.png"
import paneerSandwich from "../../assets/images/panner-sandwich.png"
import friedRice from "../../assets/images/veg-fried-rice.png"
import paneerFriedRice from "../../assets/images/paneer-fried-rice.png"
import customisedCake from "../../assets/images/customized-cake.png"
import mixedFruitCake from "../../assets/images/mix-fruit-cake.png"
import cheeseCake from "../../assets/images/cheese-cake.png"
import blackForestCake from "../../assets/images/black-forest-cake.png"
import whiteForestCake from "../../assets/images/white-forest-cake.png"
import chocolateCake from "../../assets/images/chocolate-cake.png"
import pineappleCake from "../../assets/images/pineapple-cake.png"
import butterCake from "../../assets/images/butter-scotch-cake.png"
import strawberryCake from "../../assets/images/strawberry-cake.png"
import blueberryCake from "../../assets/images/blue-berry-cake.png"
import lavaCake from "../../assets/images/lava-cake.png"
import donuts from "../../assets/images/donuts-cake.png"
import browine from "../../assets/images/browine-cake.png"
import cupCake from "../../assets/images/cup-cake.png"
import chocolatePuff from "../../assets/images/chocolate-puff.png"
import vegPuff from "../../assets/images/veg-puff.png"
import cheesePuff from "../../assets/images/cheese-puff.png"
import paneerPuff from "../../assets/images/paneer-puff.png"
import chocoMoose from "../../assets/images/choco-moose.png"
import moltenCookies from "../../assets/images/molten-chocolate-cookies.png"
import oatMealCookies from "../../assets/images/oatmeal-cookies.png"
import chocolateCookies from "../../assets/images/chocolate-cookies.png"
import butterCookies from "../../assets/images/butter-cookies.png"
import almondCookies from "../../assets/images/almond-cookies.png"
import chilliPotato from "../../assets/images/chilli-potato.png"
import honeyChilliPotato from "../../assets/images/honey-chilli-potato.png"
import chilliPaneerGravy from "../../assets/images/chilli-paneer-gravy.png"
import chilliPaneerDry from "../../assets/images/chilli-panner-dry.png"
import vegManchurianGravy from "../../assets/images/manchurian-gravy.png"
import vegManchurianDry from "../../assets/images/manchurian-dry.png"


const Menu = [
    {
        id: 1,
        name : "Mango Shake",
        category: "shake",
        img: mangoShake
    }, 
    {
        id: 2,
        name : "Orange Shake",
        category: "shake",
        img: orangeShake
    }, 
    {
        id: 3,
        name : "Pineapple Shake",
        category: "shake",
        img: pineappleShake
    }, 
    {
        id: 4,
        name : "Strawberry Shake",
        category: "shake",
        img: strawberryShake
    }, 
    {
        id: 5,
        name : "Oreo Shake",
        category: "shake",
        img: oreoShake
    }, 
    {
        id: 6,
        name : "Vanilla Shake",
        category: "shake",
        img: vanillaShake
    }, 
    {
        id: 7,
        name : "Butter Shake",
        category: "shake",
        img: butterShake
    }, 
    {
        id: 8,
        name : "Mishrambu",
        category: "shake",
        img: mishrambu
    }, 
    {
        id: 9,
        name : "Choclate Shake",
        category: "shake",
        img: choclateShake
    }, 
    {
        id: 10,
        name : "Cold Coffee",
        category: "shake",
        img: coldCoffee
    }, 
    {
        id: 11,
        name : "Hot Coffee",
        category: "hot drink",
        img: hotCoffee
    }, 
    {
        id: 12,
        name : "Hot Tea",
        category: "hot drink",
        img: hotTea
    }, 
    {
        id: 13,
        name : "Veg Burger",
        category: "burger",
        img: vegBurger
    }, 
    {
        id: 14,
        name : "Cheese Burger",
        category: "burger",
        img: cheeseBurger
    }, 
    {
        id: 15,
        name : "Paneer Burger",
        category: "burger",
        img: paneerBurger
    }, 
    {
        id: 16,
        name : "Veg Sandwich",
        category: "sandwich",
        img: vegSandwich
    }, 
    {
        id: 17,
        name : "Grilled Sandwich",
        category: "sandwich",
        img: grilledSandwich
    }, 
    {
        id: 18,
        name : "Cheese Sandwich",
        category: "sandwich",
        img: cheeseSandwich
    }, 
    {
        id: 19,
        name : "Paneer Sandwich",
        category: "sandwich",
        img: paneerSandwich
    }, 
    {
        id: 20,
        name : "Veg Fried Rice",
        category: "rice",
        img: friedRice
    }, 
    {
        id: 21,
        name : "Paneer Fried Rice",
        category: "rice",
        img: paneerFriedRice
    }, 
    {
        id: 22,
        name : "Customised Cake",
        category: "cake",
        img: customisedCake
    }, 
    {
        id: 23,
        name : "Mixed Fruit Cake",
        category: "cake",
        img: mixedFruitCake
    }, 
    {
        id: 24,
        name : "Cheese Cake",
        category: "cake",
        img: cheeseCake
    }, 
    {
        id: 25,
        name : "Black Forest Cake",
        category: "cake",
        img: blackForestCake
    }, 
    {
        id: 26,
        name : "White Forest Cake",
        category: "cake",
        img: whiteForestCake
    }, 
    {
        id: 27,
        name : "Chocolate Cake",
        category: "cake",
        img: chocolateCake
    }, 
    {
        id: 28,
        name : "Pineapple Cake",
        category: "cake",
        img: pineappleCake
    }, 
    {
        id: 29,
        name : "Butter Scotch Cake",
        category: "cake",
        img: butterCake
    }, 
    {
        id: 30,
        name : "Strawberry Cake",
        category: "cake",
        img: strawberryCake
    }, 
    {
        id: 31,
        name : "Blueberry Cake",
        category: "cake",
        img: blueberryCake
    }, 
    {
        id: 32,
        name : "Lava Cake",
        category: "cake",
        img: lavaCake
    }, 
    {
        id: 33,
        name : "Donuts",
        category: "cake",
        img: donuts
    }, 
    {
        id: 34,
        name : "Browine",
        category: "cake",
        img: browine
    }, 
    {
        id: 35,
        name : "Cup Cake",
        category: "cake",
        img: cupCake
    }, 
    {
        id: 36,
        name : "Chocolate Puff",
        category: "cookies",
        img: chocolatePuff
    }, 
    {
        id: 37,
        name : "Veg Puff",
        category: "cookies",
        img: vegPuff
    }, 
    {
        id: 38,
        name : "Cheese Puff",
        category: "cookies",
        img: cheesePuff
    }, 
    {
        id: 39,
        name : "Panner Puff",
        category: "cookies",
        img: paneerPuff
    }, 
    {
        id: 40,
        name : "Choco Moose",
        category: "cookies",
        img: chocoMoose
    }, 
    {
        id: 41,
        name : "Molten Chocholate Cookies",
        category: "cookies",
        img: moltenCookies
    }, 
    {
        id: 42,
        name : "Oat Meal Cookies",
        category: "cookies",
        img: oatMealCookies
    }, 
    {
        id: 43,
        name : "Chocolate Cookies",
        category: "cookies",
        img: chocolateCookies
    }, 
    {
        id: 44,
        name : "Butter Cookies",
        category: "cookies",
        img: butterCookies
    }, 
    {
        id: 45,
        name : "Almond Cookies",
        category: "cookies",
        img: almondCookies
    }, 
    {
        id: 46,
        name : "Chilli Potato",
        category: "indo chinese",
        img: chilliPotato
    }, 
    {
        id: 47,
        name : "Honey Chilli Potato",
        category: "indo chinese",
        img: honeyChilliPotato
    }, 
    {
        id: 48,
        name : "Chilli Paneer Gravy",
        category: "indo chinese",
        img: chilliPaneerGravy
    }, 
    {
        id: 49,
        name : "Chilli Paneer Dry",
        category: "indo chinese",
        img: chilliPaneerDry
    }, 
    {
        id: 49,
        name : "Veg Manchurian Gravy",
        category: "indo chinese",
        img: vegManchurianGravy
    }, 
    {
        id: 50,
        name : "Veg Manchurian Dry",
        category: "indo chinese",
        img: vegManchurianDry
    }, 

]

export default Menu