import './App.css';
import Navbar from './components/layout/Navbar';
import Home from './components/pages/Home';

function App() {
  return (
   <>
   <Navbar />
   <Home />
   </>
  );
}

export default App;
